.App {
  /*text-align: center;*/
  /* background-color: #f4f5f6; */
}

.App-content {
    /*text-align: center;*/
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    font-family: "Ciutadella Regular", sans-serif;
    position: relative;
    color: rgb(50, 50, 50);
    padding: 50px 10px;
    max-width: 900px;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  padding: 20px;
  color: white;
  width: 100%
}

.App-intro {
  font-size: large;
}


@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.App-tile {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  padding: 35px;
}

.btn-emoji:focus {
    /* background: #20c997; */
}

.btn-emoji:hover {
    /* background: #20c997; */
}

.btn-emoji.selected {
    /* background: #20c997; */
}

.comment-link {
    /* cursor: pointer; */
    /* background-color: aquamarine; */
}

.comment-link:hover {
    /* background-color: #20c997; */
}
